<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: ["data", "label"],
  data() {
    return {
      datacollection: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: this.label,
            data: this.data,
            borderColor: "red",
            backgroundColor: "red",
            fill: false,
          },
        ],
      },
      lineoptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    // this.chartData is created in the mixin
    this.renderChart(this.datacollection, this.lineoptions);
  },
};
</script>
