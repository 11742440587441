<template>
  <v-container v-if="loading" class="py-12">
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="6">
        <v-card class="mx-auto mb-4" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-store</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Total Products</h5>
            <h2>{{ productCount }}</h2>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="6">
        <v-card class="mx-auto mb-4" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-gift</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Total Orders</h5>
            <h2>{{ ordersCount }}</h2>
          </div>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" lg="3" md="4" sm="6">
        <v-card class="mx-auto mb-4" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-currency-usd</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Total Revenue</h5>
            <h2>{{ walletAmount }}</h2>
          </div>
        </v-card>
      </v-col> -->
      <v-col cols="12" lg="4" md="4" sm="6">
        <v-card class="mx-auto mb-4" elevation="5">
          <v-card class="pa-3 dashboard-card-inner" max-width="100">
            <v-icon color="#fff">mdi-message-draw</v-icon>
          </v-card>
          <div class="pa-4 text-right">
            <h5>Total Reviews</h5>
            <h2>{{ reviewCount }}</h2>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" sm="12" cols="12">
        <v-card v-if="orderChart" class="pa-4" elevation="5">
          <line-chart
            label="Orders"
            :data="orderChart"
            style="width:100%; position: relative; height: 300px !important"
          ></line-chart>
        </v-card>
      </v-col>
      <!-- <v-col lg="6" sm="12" cols="12">
        <v-card elevation="5">
          <sales-line-chart
            v-if="salesChart"
            label="Sales in Rs:"
            :data="salesChart"
            style="width:100%; position: relative; height: 330px !important"
          ></sales-line-chart>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
      <!-- <v-col lg="6" cols="12">
        <customer-activities></customer-activities>
      </v-col> -->
      <v-col lg="12" cols="12">
        <notice :notifications="notifications"></notice>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="py-12">
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="6" v-for="item in 4" :key="item">
        <v-skeleton-loader type="card" height="85"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" sm="12" cols="12" v-for="item in 2" :key="item">
        <v-skeleton-loader type="image" height="100%"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" cols="12" v-for="item in 2" :key="item">
        <v-skeleton-loader type="article" height="260"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

import LineChart from "../../components/dashboard/charts/LineChart";
// import SalesLineChart from "../../components/dashboard/charts/SalesLineChart";
import moment from "moment";
// import CustomerActivities from "../../components/dashboard/activities/CustomerActivities.vue";
import Notice from "../../components/dashboard/activities/Notice.vue";

export default {
  components: { LineChart,  Notice},
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      productCount: (state) => state.productCount,
      reviewCount: (state) => state.reviewsCount,
      wallet: (state) => state.wallet,
      orders: (state) => state.orders,
      notifications: (state) => state.notifications,
      customOrders: (state) => state.customOrders,

    }),
    ordersCount() {
      return this.orders.length + this.customOrders.length;
    },
    walletAmount() {
      const amt = this.wallet.reduce((acc, cur) => {
        acc = acc + cur.amount;
        return acc;
      }, 0);
      return "Rs:- " + amt;
    },
    orderChart() {
      let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.orders.forEach((item) => {
        array[moment(item.updated_at).format("M") - 1] =
          parseInt(array[moment(item.updated_at).format("M") - 1]) + 1;
      });
      return array;
    },
    salesChart() {
      let array = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.wallet.forEach((item) => {
        array[moment(item.created_at).format("M") - 1] =
          parseInt(array[moment(item.created_at).format("M") - 1]) +
          item.amount;
      });
      return array;
    },
  },
    created() {
    if (!this.productCount) {
      this.$store.dispatch("getProductsCount");
    }
    if (!this.reviewCount) {
      this.$store.dispatch("getReviewCount");
    }
  },
  mounted() {
    this.loading = true;
  },
};
</script>
<style lang="scss">
.dashboard-card-inner {
  background: rgb(255, 34, 65) !important;
  top: -1.5rem;
  left: 1rem;
  position: absolute;
}
.primary-color {
  color: $primary-color;
}
.v-btn__content {
  color: white;
}
.order-activity li,
.activity li {
  padding-bottom: 0.5rem;
}
.font-bold {
  color: #000;
  font-weight: 600;
}
</style>
