<template>
  <v-card class="mt-4" width="100%" elevation="5">
    <v-card
      class="mx-4"
      width="calc(100% - 32px)"
      elevation="5"
      style="background: #ff2241; position: absolute; top: -1rem; left: 0"
    >
      <v-card-title style="color: #fff">
        <v-icon color="#fff" class="pr-2" size="30">mdi-clipboard-file</v-icon>
        Latest Notice</v-card-title
      >
    </v-card>

    <v-card-text class="pt-12">
      <div class="subheading font-bold pt-2">Latest Important Notice</div>
      <v-divider class="my-2"></v-divider>
      <div v-if="notifications">
        <v-list-item v-for="item in notifications" :key="item.orderCode">
          <v-list-item-content>
            <v-list-item-title>
              <h5>
                <strong>{{ item.user }}</strong> has placed a new for:
                <strong
                  v-for="orderedProducts in item.items"
                  :key="orderedProducts.id"
                >
                  <li>{{ orderedProducts.product.name }}</li>
                </strong>
              </h5>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div v-else>No New Notifications</div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props:{
  notifications: {
    type: Array,
    required: true,
    default: () => [],
  },
  }
};
</script>
